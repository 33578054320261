aside#sidebar {
  position: fixed;
  height: 100vh;
  background: #ffffff;
  padding-top: 60px;
}
.pro-sidebar {
  color: #fff;
  height: 100vh !important;
  width: 263px;
  min-width: 263px;
  text-align: left;
  transition: width, left, right, 0.5s;
  position: relative;
  z-index: 1009;
  position: fixed;
  padding-top: 60px;
}

.pro-sidebar > .pro-sidebar-inner {
  background: #1d1d1d;
  height: 100%;
  position: relative;
  z-index: 101;
}
.pro-sidebar .pro-menu .pro-menu-item {
  position: relative;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #ffc45a !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #ffc45a;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item:hover {
  color: #ffc45a !important ;
}
/* .pro-sidebar .pro-menu ul li{
  padding: 6px 5px 6px 15px;
}  */

.pro-inner-item {
  width: 100%;
}
span.pro-item-content {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 500;
  margin-left: 10px;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
li.pro-menu-item.fa-solid::before {
  position: absolute;
  top: 13px;
  left: 20px;
  font-size: 16px;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background: #19191b;
  padding-left: 0 !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding: 0;
}
li.pro-menu-item {
  width: 100%;
}
.pro-sidebar .pro-menu .pro-menu-item li:hover {
  color: #3169a5;
}
/* .pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover>.pro-inner-list-item{
  visibility: hidden;
} */
.pro-sidebar.collapsed {
  width: 73px;
  min-width: 60px;
}
.pro-sidebar .closemenu {
  /* padding: 6px 5px 6px 25px;
  cursor: pointer; */
  padding: 6px 25px 6px 25px;
  cursor: pointer;
  text-align: end;
}
.pro-sidebar .closemenu i {
  transform: rotate(1turn) !important;
}
.pro-sidebar.collapsed .closemenu i {
  transform: rotate(180deg) !important;
}
.pro-sidebar.collapsed li.pro-menu-item.fa-solid::before {
  position: absolute !important;
  left: 20px !important;
  top: 13px !important;
}
.fa-gauge::before {
  content: "\f625" !important;
}
.fa-user-gear::before {
  content: "\f4fe" !important;
}
.fa-gear::before {
  content: "\f013" !important;
}
.fa-gears::before {
  content: "\f085" !important;
}
.active-submenu {
  color: #ffc45a !important;
}
/*20-10-2022*/
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background: #1d1d1d;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  background: #19191b;
}
