::-webkit-scrollbar-thumb {
  background-color: #ffc45a;
  border-radius: 20px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-outer {
  background: #fff;
}
.validation {
  color: red;
  font-size: 12px;
  font-weight: 400;
}
.custom-image {
  height: 44px;
  width: 44px;
  border-radius: 40px;
  border: 1px solid #00000059;
  object-fit: cover;
}
.heading {
  background: #000000;
  margin-top: 2%;
  justify-content: center;
  padding: 1%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.image-category {
  /* ffc45a17 */
  box-shadow: 0px 0px 15px 10px #ffc45a17;
  width: 100%;
  object-fit: contain;
  height: 150px;
  background: #f3f3f3;
}
.upload-icon {
  font-size: 28px;
  color: #000000;
  position: absolute;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
.gridrow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.gridrow button,
.image-category {
  margin-bottom: 10px;
}
.createbtn {
  text-align: right;
}
.selection-div {
  border: 1px solid #ced4da;
}
.selection-header {
  border-bottom: 1px solid #ced4da;
  text-align: center;
  padding: 2%;
  font-weight: 600;
}

.selection-services {
  width: 100%;
  height: 254px;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 10px;
  overflow-x: hidden;
  /* border-right: 1px solid #d0d0d0; */
  justify-content: flex-start;
}
.selection-service-div {
  margin-top: 1%;
  user-select: none;
  box-shadow: 0px 0px 7px rgb(79 79 115 / 20%);
  transition: 0.4s all;
}
.selection-service-values {
  justify-content: space-between;
  padding: 2%;
}
.servive-details {
  width: 85%;
  padding: 1% 4% 1% 2%;
}
.add-service-div {
  width: 100px;
}
.empty-services {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.package-days-div {
  border-top: 1px solid #c6c5c5;
}
.eye-custom {
  position: absolute;
  top: 12px;
  right: 2%;
  cursor: pointer;
}
.coming-soon {
  height: calc(100vh - 125px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

/* -------------------------- */
.btn-link,
.btn-link:hover,
.btn-outline-primary:hover {
  color: #ffc45a;
}
.btn-primary,
.btn-outline-primary,
.btn-primary:focus,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  background: #000000 !important;
  color: #ffc45a;
  border-color: #000000 !important;
}
.btn-primary:hover {
  color: #ffa600;
}
.btn-primary:focus,
.form-check-input:focus,
btn-danger:focus,
.btn-outline-primary,
.btn-link:focus,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.25rem #ffc45a;
}
.form-control,
.form-control:focus {
  border-color: #ffc45a;
}
.dropdown-item.active,
.form-check-input:checked[type="checkbox"] {
  background-color: #1d1d1d;
  border-color: #1d1d1d;
}
.dropdown-item:active {
  background-color: #e9ecef;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none !important;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
  content: none;
}
.data-table-extensions > .data-table-extensions-action > button.print {
  background-image: url("../Image/Icon/table/print.svg");
  background-size: cover;
}
.data-table-extensions > .data-table-extensions-action > button.download {
  background-image: url("../Image/Icon/table/download.svg");
  background-size: cover;
}
.data-table-extensions > .data-table-extensions-filter > .icon {
  background-image: url("../Image/Icon/table/search.svg");
  background-size: cover;
  cursor: pointer;
}
.data-table-extensions > .data-table-extensions-action > button::after {
  color: black;
  margin-top: 27px;
}
.data-table-extensions > .data-table-extensions-action > button.drop,
.data-table-extensions > .data-table-extensions-action > button:hover {
  background-color: white;
  color: #000000;
}
.fas,
.fa-solid,
button#dropdown-basic:hover {
  color: #ffc45a;
}
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 10px 28px 10px 28px !important;
}
.collapsed.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 10px 28px 10px 28px !important;
}
.is-verify-true {
  /* border: 1px solid green;
  color: green; */
  color: #11921f;
  background-color: #ddffdd;
}
.is-verify-false {
  /* border: 1px solid red;
  color: red; */
  color: #ef6262;
  background-color: #ffe7e7;
}
.is-verify-true,
.is-verify-false {
  padding: 3px 27px;
  font-weight: 500;
  width: 78px;
  height: 24px;
  font-size: 12px;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.forgot-link {
  text-align: end;
  margin-top: 3%;
  font-weight: 400;
  cursor: pointer;
  color: #000;
}
.payment-status,
.order-status {
  color: #fff;
  padding: 10px;
  font-weight: 600;
}
/* -------------------------- */

/* 10-05-2022 */
/* Css for the inner table */
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"] {
  position: relative;
}
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  > :not(div[role="gridcell"]) {
  position: absolute;
  right: 0;
  z-index: 11;
  height: 100%;
  top: 0;
}
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]:first-child
  div[role="row"]
  :not(div[data-column-id]) {
  display: none;
}

.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"] {
  background-color: #a2a2a2;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  + :not(div[role="row"])
  div[role="row"] {
  background-color: #424242;
}
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  > :not(div[role="gridcell"])
  button
  svg {
  fill: #fff !important;
}
/* Css for the inner table End*/

.reply-main {
  padding-left: 4vw;
  background-color: #cccccc17;
  transition: all 600ms ease;
  animation-name: showreply;
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}
.hide-reply {
  animation-name: hidereply;
}
@keyframes hidereply {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes showreply {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate.left.move {
  left: 100%;
  transform: translate(-100%, 0);
}

.animate.right.move {
  right: 100%;
  transform: translate(100%, 0);
}
.sliderImg {
  border-radius: 5px;
  background-size: cover;
}

.tag {
  font-size: 12px !important;
}

.scrollBox {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}
.pro-sidebar.sm.collapsed {
  left: 0 !important;
}
.pro-sidebar.sm {
  left: 0 !important;
}
/* Datepicker Color change */
.datepicker-month-label {
  color: #ffc45a;
}
.datepicker-button-previous,
.datepicker-button-next {
  background: #ffc45a;
}
.datepicker-date-day-Item:hover {
  background-color: #ffc45a;
}
.date-day-Item-selected {
  border: 2px solid #ffc45a;
  color: #ffc45a;
}

/*20-10-2022*/
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  padding-left: 0 !important;
}
.heading {
  padding: 0.6% 0.8%;
  font-size: 16px;
}

.selection-header-top {
  border-top: 1px solid #ced4da;
  margin-top: 10px;
}
.selection-header-first {
  border-right: 1px solid #ced4da;
}
.btn-danger ,.btn-secondary ,.btn-success{
  height: 35px;
  font-size: 0.875rem;
}
@media screen and (min-width: 1200px) {
  .selection-header-top {
    border: none;
    margin-top: 0;
  }
}
@media screen and (max-width: 1199px) {
  .selection-header-first {
    border: none;
  }
}
