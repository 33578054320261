.custom-header {
  background: #000000;
  height: 60px;
  z-index: 2;
  position: fixed;
  width: 100%;
}
.navbar {
  padding: 0.5rem 1rem;
}
.profile-img-area {
  height: 35px !important;
  width: 35px !important;
  border-radius: 100%;
  margin-right: 1rem;
}

button.profile-dropdown {
  padding: 0 0.75rem !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: #000000;
  /* border: none; */
}
.btn-primary.profile-dropdown,
.header-custom-dropdown,
.header-custom-dropdown:focus {
  color: #fff;
  background-color: #000000;
  border: none;
  box-shadow: none;
}
