body {
  background: #edf1f7;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}
.form-check {
  margin-bottom: 0;
}
img.login-image {
  width: 50%;
  height: auto;
}
.img-site-loader {
  height: 140px;
  width: 140px;
  margin: 0 auto;
}
.left-seciton {
  height: 100vh;
  background: #000;
  flex-direction: column;
}
.box .logo-area {
  position: absolute;
  top: 10px;
  right: 10px;
}
.logo-area {
  cursor: pointer;
}
.logo-area img {
  width: 150px;
}
.box {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.login-box {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.padding-25px-without-top {
  padding: 0 25px 25px;
}

label.login-label {
  margin: 0;
  font-size: 0.813rem;
  color: #212529;
}
.web-login {
  width: 50%;
}
.form-control {
  border: 2px solid #8c8c8c;
  padding: 0.375rem 0.5rem;
  height: calc(1.5em + 0.75rem + 2px);
}
form button.btn.btn-primary {
  width: 100%;
}
.btn-primary {
  color: #fff;
  background-color: #3169a5;
  border-color: #3169a5;
  font-size: 0.875rem;
  height: 35px;
}
.btn-outline-primary {
  border-color: #3169a5;
  height: 35px;
  font-size: 0.875rem;
}
main#main {
  padding: 65px 15px 80px;
  position: relative;
  min-height: 100vh;
  margin-left: 263px;
  min-width: 100vh;
  transition: width, left, right, 0.5s;
}
.pro-sidebar.collapsed + #main {
  margin-left: 73px !important;
}
main .form-control {
  border: 1px solid #ced4da;
}
.login-button a {
  width: 100%;
}
.form-select {
  font-size: 0.875rem;
}
/* data table css */
.data-table-extensions {
  margin-bottom: 12px;
}
.sc-bczRLJ.eSTlnH.rdt_Table {
  background: transparent;
}
.sc-dkzDqf.gZWIKX.rdt_TableHeadRow {
  min-height: 48px !important;
  border-radius: 5px;
  border: none !important;
}
.sc-jqUVSM.kgEPYv.rdt_TableRow {
  min-height: 45px !important;
  border-radius: 5px;
  margin-top: 5px;
  border: none !important;
}
nav.sc-iIPllB.jxflYm.rdt_Pagination {
  background: transparent;
  border: none;
  display: flex;
  /* justify-content: space-between; */
}
.DatePicker_dateDayItem__XwRQy {
  justify-content: center !important;
}

.mainDiv {
  display: flex;
  gap: 25px;
  margin: 25px 0px 25px 25px;
}

input {
  outline: none;
  border: none;
}
.multi-selector {
  width: max-content;
}
.select-field {
  border: 1px solid rgb(187, 187, 187);
}
.select-field,
.task,
.list {
  width: 100%;
  background-color: white;
  padding: 0.3rem;
}
.list {
  box-shadow: 0 30px 60px rgb(0, 0, 0, 0.2);
  display: none;
}
.down-arrow {
  font-size: 1.2rem;
  display: inline-block;
  cursor: pointer;
  transition: 0.2s linear;
}
.task {
  display: block;
  padding-left: 0;
}
.task span {
  float: right;
  font-size: 0.6rem;
  padding-top: 6px;
}
.task:hover {
  background-color: aliceblue;
}
.show {
  display: block;
}
.rotate180 {
  transform: rotate(-60deg);
}
.border-radius-12 {
  border-radius: 12px;
}
.cursor-pointer {
  cursor: pointer;
}
/* Dashboard */
.dashboard-cards-icon {
  font-size: 19px;
  height: 30px;
  width: 30px;
}
.dash-appointments-main {
  /* height: 400px; */
  min-height: 400px;
  overflow-y: scroll;
}
.breadcrum-navigation-title {
  font-size: 19px;
}
.custom-breadcrum-inactive:hover {
  color: #eeb305 !important;
}
.lazy-load-image {
  width: 100%;
  height: 40vh;
  margin: auto;
  object-fit: cover;
  object-position: center;
  background-color: #000;
  position: relative;
  border-radius: 5px;
}
.customer-view-image {
  height: 100%;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #ffc45a;
}
.order-image {
  height: 68%;
  background-size: cover;
  width: 25%;
  border-radius: 5px;
}
.image {
  width: 100px;
  height: 100px;
  margin: auto;
}
.socialImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 1px solid #ffc45a;
}
.orderImg {
  width: 100px;
  height: 100px;
  margin: 0px auto 10px;
}
.orderImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #ffc45a;
  object-fit: cover;
  object-position: center;
}
.order-name {
  /* background-color: #1d1d1d;
  color: white; */
  padding: 10px 13px;
  font-size: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 18px;
}
.accordion-button.exapanded {
  color: black;
}
.accordianBody {
  display: none;
}
.accordion-order .accordion-button::after {
  content: none;
}
.accordion-order .accordion-button:focus {
  box-shadow: none;
  background: none;
}
.wb-keep-a {
  word-break: break-all;
}
.badge {
  width: 78px;
  height: 21px;
  font-size: 11px;
}
.badge-order-pending {
  background-color: gray !important;
}
.badge-order-confirmed {
  background-color: lightgreen !important;
}
.badge-order-completed,
.badge-order-online {
  background-color: #38761d !important;
}
.badge-order-processed {
  background-color: #ff9900 !important;
}
.badge-order-processing {
  background-color: #073763 !important;
}
.badge-order-cancelled,
.badge-order-offline {
  background-color: #cc0000 !important;
}
.badge-order-rejected {
  background-color: #660000 !important;
}
.font-600 {
  font-weight: 600;
}
.like-model .modal-dialog {
  max-width: 70%;
}
.sub-menu-active {
  position: relative;
  color: #ffc45a !important;
}
.sub-menu-active::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  border-radius: 50px;
  background-color: #ffc45a !important;
  left: 0;
  top: 0;
}
.sub-menu-active::before {
  content: none;
}
.pro-sidebar .pro-menu a {
  color: #fff !important;
}

.progressbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  width: 100%;
  z-index: 9999;
}

.progress-bar {
  background-color: #ffc45a !important ;
}

.orderDate {
  background-color: #ffc45a;
  color: white;
  border-radius: 40px;
}
.custom-breadcrum-active {
  color: #ffc45a !important;
}
.custom-breadcrum-inactive {
  color: #6c757d !important;
}

/*20-10-2022*/
.DatePicker_dayLabel__\+Tm0A {
  margin: 3px 0 0 0;
}
.DatePicker_dateLabel__iIowA {
  line-height: 14px;
}
.DatePicker_buttonWrapper__BW4Q\+ .DatePicker_button__OmKeB {
  margin-bottom: 8.5px;
  font-size: 25px;
}
.DatePicker_container__-ZIRk {
  position: relative;
  z-index: 1;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding: 0 0.75rem;
}
.container .row,
.container-fluid .row,
.container-lg .row,
.container-md .row,
.container-sm .row,
.container-xl .row,
.container-xxl .row {
  margin: 0 -0.75rem;
}
.container .row [class^="col-"],
.container-fluid .row [class^="col-"],
.container-lg .row [class^="col-"],
.container-md .row [class^="col-"],
.container-sm .row [class^="col-"],
.container-xl .row [class^="col-"],
.container-xxl .row [class^="col-"] {
  padding: 0 0.75rem;
}

.react-switch-bg {
  height: 18px !important;
  width: 46px !important;
}
.react-switch-handle {
  width: 16px !important;
  height: 16px !important;
}
/* .login-page .logo-area img {
    width: 280px;
    padding-top: 0;
    position: absolute;
    bottom: 70px;
    left: 0;
    margin: 0 auto;
    right: 0;
  } */
.login-image {
  width: 40%;
  display: none;
}
.left-seciton {
  position: relative;
  padding: 15px;
}
.login-page .logo-area img {
  width: 500px;
  padding-top: 0;
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  transform: translateY(-50%);
  top: 50%;
}
.web-login {
  /* width: 50%; */
  width: 470px;
}

.react-table .rdt_TableCol:last-child,
.react-table .rdt_TableCell:last-child {
  justify-content: right;
}
.rdt_TableCol {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 16px;
}
.rdt_TableCell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.sc-jqUVSM.kgEPYv.rdt_TableRow {
  border-radius: 0;
}
.edit-icon .fas.fa-edit,
.trash-icon .fa.fa-trash,
.eye-icon .fas.fa-eye {
  color: #000;
  cursor: pointer;
  font-size: 15px;
}
.gridrow .image-category {
  box-shadow: none;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.badge.primary-label {
  color: #11a4e2;
  background-color: #e7f0ff;
}
.badge.danger-label {
  color: #ef6262;
  background-color: #ffe7e7;
}
.badge.success-label {
  color: #11921f;
  background-color: #ddffdd;
}
.badge.badge-offline-label {
  background: rgba(169, 173, 179, 0.2);
  color: #a9adb3;
}
.badge.badge-online-label {
  background: rgba(18, 183, 106, 0.2);
  color: #12b76a;
}
.badge.badge-order-processed {
  background-color: rgb(245 147 0 / 23%) !important;
  color: #ff9900;
}
.badge.badge-order-processing {
  background-color: rgb(7 55 99 / 23%) !important;
  color: #073763;
}
.change-password-row:first-child {
  margin-top: 0 !important;
}
.kgEPYv:hover {
  background-color: #f9f9f9 !important;
}

.DatePicker_buttonWrapper__BW4Q\+ {
  background-color: #ffffff !important;
}
.accordion .accordion-button::after {
  position: absolute;
  top: 15px;
  right: 2px;
}
.accordion-tabs {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  word-break: break-word;
}
.sc-hKMtZM.sc-eCYdqJ.sc-iqcoie.jzGaAN.kSJejC.crSOFH.rdt_TableCol {
  justify-content: unset;
}
.rdt_TableRow .kSJejC {
  justify-content: unset;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .login-page .logo-area img {
    width: 320px;
  }
  .web-login {
    width: 85%;
  }
}
@media only screen and (min-width: 992px) {
  .border-first-right {
    border-right: 1px solid #dee2e6;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1159px) {
  main#main {
    margin-left: 73px;
    min-width: unset;
  }
  .DatePicker_dateDayItem__OQwB0 .DatePicker_dateLabel__iIowA {
    font-size: 14px;
  }
  .pro-sidebar {
    box-shadow: 15px 4px 15px -3px rgba(29, 29, 29, 0.2);
  }
  .pro-sidebar.collapsed {
    box-shadow: none;
  }
  .accordion-tabs {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  .border-second-top {
    border-top: 1px solid #dee2e6;
  }
  main#main {
    margin-left: 73px;
    min-width: unset;
  }
  .DatePicker_dateDayItem__OQwB0 .DatePicker_dateLabel__iIowA {
    font-size: 14px;
  }
  .pro-sidebar {
    box-shadow: 15px 4px 15px -3px rgba(29, 29, 29, 0.2);
  }
  .pro-sidebar.collapsed {
    box-shadow: none;
  }
  .gridrow {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 20px !important;
  }
  .accordion-tabs {
    grid-template-columns: repeat(3, 1fr);
  }
}
