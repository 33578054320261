footer#footer {
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #1d1d1d;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222b45;
  font-weight: 600;
  margin-left: -15px;
}
.footer {
  color: #fff;
  border-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 12.5px;
  width: 100%;
  font-size: 0.875rem;
}
