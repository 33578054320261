
.customerName{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 3px;
}

.social{
    width: 118px;
    text-align: center;
    margin-bottom: 2px;
    font-weight: bold;
}

.socialDetail label, .socialDetail p{
    font-size: 17px;
}

.socialDetail label{
    font-weight: bold;
}
