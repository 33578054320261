.loader-div {
    height: 100%;
    background-color: #00000008;
    width: 100%;
    left: 0;
    top: 0;
    font-size: 29px;
    position: fixed;
    justify-content: center;
    font-weight: 500;
    display: flex;
    font-family: serif;
    text-align: center;
    z-index: 9999;
    flex-direction: column;
}

.loader-round {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #ffc45a;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }